@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';
@import "styles/settings/variables";

$horizontal-spacing: 20px;
$forward-icon-inset: $horizontal-spacing * 0.5;
$menu-icon-width: 20px;
$account-item-height-mobile: 40px;
$menu-item-height: 38px;
$menu-item-padding-left-desktop: 15px;
$menu-item-padding-left-mobile: 20px;
$menu-item-padding-right: 25px;
$menu-padding-top: 16px;
$menu-panel-padding-left-desktop: 40px;
$menu-panel-padding-right-desktop: 20px;
$border-colour: $colour-oyster-grey !default;
$font-size: $font-size-h6;
$link-vertical-padding-small: $grid-vertical-gutter * .75;
$link-horizontal-padding-small: $grid-vertical-gutter * 1.25;
$slide-duration: 200ms;

@mixin menu-item-hover {
  color: $colour-white;
  background-color: $colour-waitrose-grey;

  path {
    fill: $colour-white;
  }
}

@mixin menu-max-height {
  max-height: 560px;
  max-height: calc(100vh - 255px);
}

