@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/spacing';
@import '../variables';

.trolley {
  display: none;
  flex-basis: auto;
  justify-content: flex-end;
  margin-left: $ingredients-unit * 2;
  order: 3;
  position: relative;

  @include media-breakpoint-up('md') {
    display: flex;
  }
}

.signInOrTrolley {
  text-decoration: underline;
  align-items: center;
  display: flex;
  height: $top-height;
  justify-content: flex-end;
  margin-left: 20px;
  max-width: ($ingredients-unit * 20);
  position: relative;
  order: 5;
  white-space: nowrap;

  @include media-breakpoint-up('md') {
    display: none;
  }
}
