@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/animation';
@import '../common';

%column {
  @include menu-max-height;
  -webkit-overflow-scrolling: touch; // smooth scroll on iOS
  animation-delay: 10ms;
  animation: $ingredients-fade-in-animation;
  margin: 0;
  overflow: auto;

  @include media-breakpoint-up('lg') {
    padding: $menu-padding-top 0;
    width: 38%;
  }
}

.column2 {
  @extend %column;
  background-color: $colour-eggshell-grey;
}

.column2 :global(#mega-menu-second-column) {
  padding: 0;

  h4 {
    font-weight: 400;
    padding-bottom: 6px;
    padding-right: ($ingredients-unit * 2);
    padding-left: ($ingredients-unit * 10);
  }

  section {
    padding: 0;
    margin-bottom: 0;
  }

  section:first-of-type h4 {
    padding-top: ($ingredients-unit * 2);
  }

  section:not(:first-of-type) h4 {
    margin-top: 50px;
  }
}

.column3 {
  @extend %column;
  background-color: $colour-white;
}
