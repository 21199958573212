@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins/media-queries';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/helpers/component-visibility';
@import '../../../styles/settings/variables';

$zindex-dropdown: 1000;

.wrapper {
  display: none;
  background: $colour-white;
  padding: 0;
  position: relative;
  z-index: $zindex-dropdown;
  line-height: normal;

  @include media-breakpoint-up('lg') {
    display: block;
  }

  .topNavContainer {
    // TODO remove this once xxl is working fine on DS
    max-width: map-get($grid-breakpoints, 'xl');
  }
}

.row {
  display: flex;
  height: $links-height;
  margin-bottom: 10px;
}

.legend {
  color: $colour-primary-grey;
  font-weight: 200;
}

.specialistShopLinksWrapper {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  align-items: center;
  display: flex;
  flex: 1 0 auto;
}

.specialistShopLink {
  color: $colour-primary-grey;
  font-weight: 400;

  @include media-breakpoint-up('lg') {
    display: inline-block;
    font-weight: 400;
    line-height: 1;
    padding: 0 $grid-vertical-gutter;
    position: relative;
  }

  @include media-breakpoint-up('xl') {
    padding: 0 $grid-vertical-gutter;
  }
}

.customerServiceLinksWrapper {
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  text-transform: none;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
}

.customerServiceLink {
  color: $colour-primary-grey;
  font-weight: 400;

  &:focus {
    outline: {
      color: $colour-focus-blue;
      offset: $focus-outline-width;
      style: solid;
      width: $focus-outline-width;
    }
  }

  @include media-breakpoint-up('lg') {
    display: inline-block;
    line-height: 1;
    margin-left: $grid-vertical-gutter * 2;
    position: relative;

    &::after {
      bottom: 0;
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  @include media-breakpoint-up('xl') {
    margin-left: $grid-vertical-gutter * 2;
  }
}
