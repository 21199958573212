@import '~@johnlewispartnership/wtr-ingredients/dist/foundations/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';

.maxLengthMessage {
  position: relative;
  top: -16px;
  display: block;
  background: $colour-mushroom-grey;
  height: 40px;
  line-height: 40px;
  margin: 0;
  overflow: hidden;
  text-align: center;
  transition: height 0.6s linear;
  white-space: nowrap;
  width: 100%;
}

.content {
  @include media-breakpoint-down('md') {
    height: 100%;

    // Override height for TextArea container div
    & > div {
      height: calc(100% - 14px);
    }
  }
}

.textArea {
  height: 378px;

  @include media-breakpoint-down('md') {
    height: 100%;
  }
}
