@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/focus';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/spacing';
@import 'styles/settings/variables';
@import 'styles/tools/mixins';

.richText {
  margin-bottom: $grid-vertical-gutter;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.noMargin {
  margin: 0;
}

.richTextParagraph {
  @include paragraph;
}

.richTextList {
  @include paragraph;
  padding-left: $grid-vertical-gutter;
}

.fontSize60px {
  @include display-title('light');
}

.fontSize36px {
  @include title-h1('light');
}

.fontSize28px {
  @include title-h2('light');
}

.fontSize20px {
  @include title-h3('light');
}

.fontSize16px {
  @include paragraph('light');
}

.fontSize14px {
  @include paragraph-sub('light');
}

.fontWeightLight {
  @include font-weight('light');
}

.fontWeightMedium {
  @include font-weight('medium');
}

.fontWeightSemibold {
  @include font-weight('bold');
}

.fontWeightUltrawide {
  @include font-weight('medium');
  @include ultra-wide-fixed-size;
}

.richTextStrong {
  font-weight: 400;
}

.richTextH1 {
  @include title-h2('light');
}

.richTextH2 {
  @include title-h3('light');
  font-size: 20px;
  line-height: 1.5;
}

// H3, H4, H5, H6 - keep same size as Paragraph - we only include those for accessibility
.richTextH3 {
  @include paragraph;
}

.richTextH1,
.richTextH2,
.richTextH3,
.richTextParagraph {
  & > strong {
    font-weight: 400;
  }
}

.anchor {
  font-weight: 400;
  text-decoration: underline;
  color: inherit;

  &:hover,
  &:focus {
    color: inherit;
  }

  &:focus {
    @include focus-outline;
  }

  // Inherit the font-weight only when it's specified in the RTE.
  span[style*='color']:not(span[class*='fontWeight']) {
    font-weight: inherit;
  }

  // If the nested span has a font-size, we'll set the text-decoration on it and remove it from the link.
  &:has(span[class*='fontSize']) {
    text-decoration: none;
  }

  // In the scenario where a span is nested within a link, we need to reapply the underline to ensure the correct font-size is applied to it.
  span[class*='fontSize'] {
    text-decoration: underline;
  }
}

// Inherit the font-weight only when it's specified in the RTE.
.richTextParagraph span[class*='fontWeight'] a {
  font-weight: inherit;
}

.fontFamilySebenta {
  font-family: Sebenta;
  font-weight: 700;
  margin: 0 0 10px;

  &.fontSize124px {
    font-size: 124px;
    line-height: 140px;
  }

  &.fontSize108px {
    font-size: 108px;
    line-height: 124px;
  }

  &.fontSize92px {
    font-size: 92px;
    line-height: 108px;
  }

  &.fontSize84px {
    font-size: 84px;
    line-height: 100px;
  }

  &.fontSize76px {
    font-size: 76px;
    line-height: 88px;
  }

  &.fontSize68px {
    font-size: 68px;
    line-height: 80px;
  }

  &.fontSize60px {
    font-size: 60px;
    line-height: 72px;
  }

  &.fontSize52px {
    font-size: 52px;
    line-height: 64px;
  }

  &.fontSize44px {
    font-size: 44px;
    line-height: 52px;
  }

  &.fontSize36px {
    font-size: 36px;
    line-height: 44px;
  }

  &.fontSize28px {
    font-size: 28px;
    line-height: 36px;
  }

  &.fontSize20px {
    font-size: 20px;
    line-height: 24px;
  }

  &.fontSize16px {
    font-size: 16px;
    line-height: 20px;
  }

  &.fontSize14px {
    font-size: 14px;
    line-height: 18px;
  }
}

.fontFamilySohne {
  font-family: SohneSchmal;
  text-transform: uppercase;
  margin: 0 0 10px;
  line-height: 1;
  font-size: 52px;

  &.fontSize480px {
    line-height: 432px;
    font-size: 480px;
  }

  &.fontSize420px {
    line-height: 378px;
    font-size: 420px;
  }

  &.fontSize360px {
    line-height: 324px;
    font-size: 360px;
  }

  &.fontSize300px {
    line-height: 270px;
    font-size: 300px;
  }

  &.fontSize240px {
    line-height: 228px;
    font-size: 240px;
  }

  &.fontSize200px {
    line-height: 190px;
    font-size: 200px;
  }

  &.fontSize160px {
    line-height: 152px;
    font-size: 160px;
  }

  &.fontSize140px {
    line-height: 133px;
    font-size: 140px;
  }

  &.fontSize124px {
    line-height: 118px;
    font-size: 124px;
  }

  &.fontSize108px {
    font-size: 108px;
  }

  &.fontSize92px {
    font-size: 92px;
  }

  &.fontSize76px {
    font-size: 76px;
  }

  &.fontSize60px {
    font-size: 60px;
  }

  &.fontSize44px {
    font-size: 44px;
  }

  &.fontSize36px {
    font-size: 36px;
  }
}
