%nav-title {
  background: $colour-scallop-grey;
  color: $colour-primary-grey;
  font: {
    size: 14px;
    weight: 500;
  }
  display: block;
  letter-spacing: 1.53px;
  line-height: 20px;
  margin: 0;
  overflow: hidden;
  padding: 25px 20px 10px;
  white-space: normal;
  text-transform: uppercase;
}
