@mixin font-weight($weight) {
  @if $weight == 'light' {
    font-weight: 200;
  } @else if $weight == 'medium' {
    font-weight: 400;
  } @else if $weight == 'bold' {
    font-weight: 700;
  } @else {
    font-weight: 400;
  }
}

@mixin display($weight: 'light') {
  @include font-weight($weight);

  font: {
    family: $font-family-base;
    size: 28px;
    stretch: normal;
    style: normal;
  }
  letter-spacing: normal;
  line-height: 1.2;
  margin: 20px 0 10px;

  @include media-breakpoint-up('md') {
    font-size: 36px;
  }

  @include media-breakpoint-up('xl') {
    font-size: 60px;
  }
}

@mixin heading-large($weight: 'light') {
  @include font-weight($weight);

  font: {
    family: $font-family-base;
    size: 28px;
    stretch: normal;
    style: normal;
  }
  letter-spacing: normal;
  line-height: 1.22;
  margin: 20px 0 10px;

  @include media-breakpoint-up('sm') {
    font-size: 36px;
  }
}

@mixin heading($weight: 'light') {
  @include font-weight($weight);

  font: {
    family: $font-family-base;
    size: 20px;
    stretch: normal;
    style: normal;
  }
  letter-spacing: normal;
  line-height: 1.29;
  margin: 20px 0 10px;

  @include media-breakpoint-up('sm') {
    font-size: 28px;
  }
}

@mixin heading-small($weight: 'light') {
  @include font-weight($weight);

  font: {
    family: $font-family-base;
    size: 16px;
    stretch: normal;
    style: normal;
  }
  letter-spacing: normal;
  line-height: 1.4;
  margin: 20px 0 10px;

  @include media-breakpoint-up('md') {
    font-size: 20px;
  }
}

@mixin body($weight: 'light') {
  @include font-weight($weight);

  font: {
    family: $font-family-base;
    size: 16px;
    stretch: normal;
    style: normal;
  }
  line-height: 1.5;
  letter-spacing: normal;
  margin: 0 0 10px;
}

@mixin body-small($weight: 'light') {
  @include font-weight($weight);

  font: {
    family: $font-family-base;
    size: 14px;
    stretch: normal;
    style: normal;
  }
  line-height: 1.43;
  letter-spacing: normal;
  margin: 0 0 10px;
}

@mixin ultra-wide-fixed-size() {
  letter-spacing: 4px;
  text-transform: uppercase;
}

@mixin ultra-wide-text($size: 'heading') {
  @if $size == 'heading' {
    @include heading('medium');
  } @else if $size == 'heading-small' {
    @include heading-small('medium');
  } @else {
    @include body-small('medium');
  }
  @include ultra-wide-fixed-size;
}

// Below are the legacy styles that should be phased out/moved out of WDX

@mixin letter-spacing($size: 5px, $indent: false) {
  letter-spacing: $size;

  @if $indent == true {
    text-indent: $size;
  }
}

@mixin display-title($weight: 'medium') {
  @include font-weight($weight);

  font: {
    family: $font-family-base;
    size: 28px;
    stretch: normal;
    style: normal;
  }
  letter-spacing: normal;
  line-height: 1.285714285714286; // ~36px

  @include media-breakpoint-up('md') {
    font-size: 36px;
    line-height: 1.222222222222222; // ~44px
  }

  @include media-breakpoint-up('xl') {
    font-size: 60px;
    line-height: 1.2; // ~72px
  }
}

@mixin title-h1($weight: 'medium') {
  @include font-weight($weight);

  font: {
    family: $font-family-base;
    size: 28px;
    stretch: normal;
    style: normal;
  }
  letter-spacing: normal;
  line-height: 1.285714285714286; // ~36px

  @include media-breakpoint-up('sm') {
    font-size: 36px;
    line-height: 1.444444444444444444; // ~52px
  }
}

@mixin title-h2($weight: 'medium') {
  @include font-weight($weight);

  font: {
    family: $font-family-base;
    size: 20px;
    stretch: normal;
    style: normal;
  }
  letter-spacing: normal;
  line-height: 1.285714285714286; // ~36px

  @include media-breakpoint-up('sm') {
    font-size: 28px;
  }
}

@mixin title-alert($weight: 'light') {
  @include font-weight($weight);
  font-size: 20px;
  line-height: 1.4;
}

@mixin title-h3($weight: 'medium') {
  @include font-weight($weight);

  font: {
    family: $font-family-base;
    size: 16px;
    stretch: normal;
    style: normal;
  }
  letter-spacing: normal;
  line-height: 1.5;

  @include media-breakpoint-up('md') {
    font-size: 20px;
    line-height: 1.4;
  }
}

@mixin title-modal($weight: 'medium') {
  @include font-weight($weight);

  font: {
    family: $font-family-base;
    size: 20px;
    stretch: normal;
    style: normal;
  }
  letter-spacing: normal;
  line-height: normal;
}

@mixin paragraph($weight: 'light') {
  @include font-weight($weight);

  font: {
    family: $font-family-base;
    size: 16px;
    stretch: normal;
    style: normal;
  }
  line-height: 1.5;
  letter-spacing: normal;
}

@mixin paragraph-sub($weight: 'light') {
  @include paragraph($weight);

  font-size: 14px;
}

@mixin ultra-wide($centre: false) {
  @include letter-spacing(5px, $centre);
  text-transform: uppercase;

  @include media-breakpoint-up('md') {
    @include letter-spacing(6px, $centre);
  }

  @include media-breakpoint-up('xl') {
    @include letter-spacing(9px, $centre);
  }
}

@mixin no-print {
  @media print {
    display: none !important; // sass-lint:disable-line no-important
  }
}
