@import '../common';

.list {
  align-items: center;
  font-weight: 200;
  list-style: none;
  padding: 0;

  .heading:not(:first-child) {
    margin-top: $menu-item-height;
  }
}

.item {
  margin-top: 0;
}
