@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';

@keyframes slide-in-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(100vh);
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.cancel {
  align-items: center;
  display: flex;
  flex-grow: 0;
  padding-left: 32px;

  button {
    border: 0;
  }
}

.dialog {
  animation: slide-in-from-bottom .2s ease both;
  background-color: $body-bg;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $zindex-modal;
}

.header {
  background-color: $colour-scallop-grey;
  display: flex;
  padding: {
    bottom: 11px;
    left: 16px;
    right: 16px;
    top: 11px;
  }
}

.multisearch {
  align-items: center;
  background-color: $colour-scallop-grey;
  display: flex;
  flex-basis: 100%;
  height: 45px;
  margin-top: 1px;
  padding: {
    left: 16px;
    right: 16px;
  }
}

.search {
  flex-grow: 1;

  form {
    margin: 0;
  }
}
