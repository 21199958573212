@import '~@johnlewispartnership/wtr-ingredients/dist/foundations/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/focus';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/spacing';
@import '../common';

%item {
  @include focus-inset;
  align-items: center;
  display: flex;
  min-height: $menu-item-height;

  padding: {
    left: $menu-panel-padding-left-desktop;
    right: $menu-panel-padding-right-desktop;
  }
}

.card[role='link'] {
  @extend %item;

  $card-padding: $ingredients-unit * 6;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: $colour-white;
  border: 1px solid $colour-mushroom-grey;
  margin: $ingredients-unit * 4 $ingredients-unit * 7 $ingredients-unit * 4 $ingredients-unit * 10;
  padding: 0;
  min-height: 80px;

  &:focus,
  &:hover {
    background-color: $colour-scallop-grey;
    text-decoration: none;

    .name {
      text-decoration: underline;
    }
  }
}

.heading {
  @extend %item;
  font-weight: 400;
  line-height: 1.5;
  white-space: pre;
}

.newHeading {
  @extend .heading;
  font-size: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin-top: $ingredients-unit * 4;
}

.link {
  @extend %item;
  align-items: center;
  color: $text-color;
  height: $menu-item-height;
}

.name {
  display: inline;
  height: auto;
  font-weight: 500;
  letter-spacing: 2.63px;
}

.small {
  @extend %item;
  display: inline;
  padding: $ingredients-unit * 2;
  padding-top: 10px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: $ingredients-unit * 5;
}

.smallWrapper {
  margin: 0 $ingredients-unit * 10 $ingredients-unit * 3;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subtext {
  display: block;
  margin-top: 9px;
  white-space: normal;
}

.linkImg {
  width: 80px;
  min-height: 80px;
  object-fit: cover;
  align-self: stretch;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 60px;
  padding: $ingredients-unit * 2 $ingredients-unit * 2 $ingredients-unit * 2 $ingredients-unit * 5;
}
