@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/focus';

.multiSearchButton {
  @include focus;

  appearance: none;
  text-decoration: underline;
  border: 0;
  background: 0;
  font-weight: 200;
  height: 38px;
  line-height: $font-size-large;
  position: absolute;
  padding: 0;
  right: 53px;
  top: 0;
  z-index: 2;

  &:active {
    outline: 0;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.chevronRightIcon {
  margin: 4px;
}

.mobileMultiSearchButton {
  background-color: transparent;
  border: 0;
  font-weight: 100;
  padding: 0;
  text-decoration: underline;
}
