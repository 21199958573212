@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/spacing';

$padding: $ingredients-unit * 3;

.skipLink {
  background: $colour-waitrose-green;
  color: $colour-white;
  line-height: 1.4;
  font: {
    family: $font-family-base;
    size: 20px;
    weight: 700;
    stretch: normal;
    style: normal;
  }
  letter-spacing: 1px;
  left: 0;
  opacity: 0;
  padding: ($padding * 0.5) $padding;
  pointer-events: none;
  position: absolute;
  text-transform: uppercase;
  transform: translateY(-100%);
  transition: opacity 0.5s, transform 0.5s;
  z-index: $zindex-skip-links;

  &:focus {
    color: $colour-white; // overriding 'a' defaults
    opacity: 1;
    outline: 0;
    pointer-events: auto;
    text-decoration: none;
    transform: translateY(0);
  }
}
