@import '~@johnlewispartnership/wtr-ingredients/dist/foundations/colours';

@mixin site-header-link($colour: $colour-waitrose-green) {
  &::after {
    background: $colour;
    bottom: -1px;
    content: '';
    display: block;
    height: 3px;
    left: 50%;
    margin-left: 0;
    position: absolute;
    transition: margin-left 0.2s, width 0.2s;
    width: 0;
  }

  &:hover {
    &::after {
      $underline-width: 110%;
      margin-left: ($underline-width * 0.5) * -1;
      width: $underline-width;
    }
  }
}
