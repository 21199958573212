@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';

.amendOrderAlert {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up('md') {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  svg {
    path {
      fill: $colour-white;
    }
  }
}

.titleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  order: 1;
  padding: 0 16px;

  .iconContainer {
    display: flex;

    .amendIconTxt {
      color: $colour-white;
      margin-left: 8px;
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-up('md') {
    justify-content: center;
    padding: 0 $padding-xxl-vertical 0 $padding-xl-vertical;
  }
}

.checkoutTooltipContent {
  display: flex;

  .checkoutTooltipText {
    margin-right: $margin-small-vertical;
  }
}

.details,
.ctas {
  padding: 16px;
}

.details {
  border-top: 1px solid $colour-waitrose-grey;
  margin: 0;
  order: 4;

  @include media-breakpoint-up('md') {
    order: 2;
    border: {
      color: $colour-waitrose-grey;
      style: solid;
      width: 0 1px;
    }
    flex-grow: 1;

    padding: 0 $padding-xl-vertical;
  }

  @include media-breakpoint-up('lg') {
    min-width: 50%;
  }

  p {
    line-height: 24px;
    @include media-breakpoint-up('md') {
      margin: 0;
    }
  }
}

.ctas {
  padding-top: 0;
  order: 2;

  @include media-breakpoint-up('md') {
    align-items: center;
    display: flex;
    padding: 0 0 0 $padding-xl-vertical;

    > * {
      margin: auto;
    }
  }

  @include media-breakpoint-up('md') {
    order: 3;
  }

  @include media-breakpoint-up('lg') {
    padding-right: $padding-xxl-vertical;

    div:first-child > button {
      width: 200px;
    }
  }
}

.amendOrderCutOff {
  display: block;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 0;
  color: $colour-white;

  @include media-breakpoint-up('md') {
    margin-top: auto;
  }
}

.cutOffDateTime {
  font-weight: 700;
  white-space: nowrap;
  color: $colour-white;
}

.slotInfo {
  color: $colour-white;
  text-overflow: ellipsis;
  overflow: hidden;

  .slotDate {
    font-weight: 400;
  }

  .slotTime {
    font-weight: 400;
    text-transform: lowercase;
  }
}

.discard {
  text-decoration: underline;
  background: $colour-transparent;
  border: 0;
  cursor: pointer;
  line-height: 24px;
  margin-top: $margin-small-vertical;
  padding: 4px 0;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  color: $colour-white;

  @include media-breakpoint-up('md') {
    margin: 0 $margin-small-vertical 0 0;
    padding: 0;
    order: -1;
    text-align: right;
    white-space: nowrap;
  }
}

.expandChevron {
  background: $colour-transparent;
  border: 0;
  padding: 0;
  touch-action: manipulation;
  user-select: none;
  height: 50px;
  line-height: 50px;
  text-align: center;

  &,
  &:hover,
  &:focus,
  &:active {
    color: $colour-primary-grey;
  }

  &::after {
    content: '\e911';
    display: inline-block;
    font: {
      family: 'WaitroseGlyph';
      size: 20px;
    }
    color: $colour-white;
    position: relative;
    transform: rotate(270deg);
    transition: transform 500ms;
    vertical-align: bottom;

    .open & {
      transform: rotate(90deg);
    }
  }

  @include media-breakpoint-up('md') {
    display: none;
  }
}

.tooltipReference {
  align-self: flex-end;
  position: relative;
  top: 15px;
  order: 0;
  left: -16px;
  @include media-breakpoint-up('md') {
    align-self: flex-start;
    top: 0;
    order: 4;
    left: 0;
  }

  @include media-breakpoint-up('lg') {
    left: -$padding-xxl-vertical;
  }
}
