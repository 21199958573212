@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/focus';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/spacing';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';
@import '../mixins';

$icon-size: $ingredients-unit * 7;

%button {
  $fast: 0.2s;

  background-image: none;
  border: 0;
  border-radius: 0;
  display: inline-block;
  cursor: pointer;
  font: {
    family: Waitrose;
    size: $font-size-base;
    weight: 400;
  }
  line-height: $line-height-base;
  padding: $padding-base-vertical $padding-base-horizontal;
  text-align: center;
  touch-action: manipulation;
  transition: background-color $fast, border-color $fast, color $fast;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &,
  &:active {
    &:focus {
      @include tab-focus;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:active {
    background-image: none;
    outline: 0;
  }

  &[disabled] {
    box-shadow: none;
    pointer-events: none;

    &,
    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
      background: $colour-mushroom-grey;
      color: $colour-chia-grey;
      cursor: auto;
      opacity: 1;
    }
  }
}

%text-button {
  @extend %button;
  border-radius: 0;
  color: $link-color;
  font-weight: normal;
  line-height: 1.5;
  margin-top: 0;

  &,
  &:active,
  &[disabled] {
    background-color: $colour-transparent;
    box-shadow: none;
  }

  &,
  &:hover,
  &:focus,
  &:active {
    border-color: $colour-transparent;
  }

  &:hover,
  &:focus {
    background-color: $colour-transparent;
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &[disabled] {
    &:hover,
    &:focus {
      color: $colour-mushroom-grey;
      text-decoration: none;
    }
  }
}

.accountActionWrapper {
  margin-right: 16px;
  margin-left: 6px;
  position: relative;
  width: auto;
}

.accountActions {
  margin: 0;
  padding: 0;
}

.button {
  align-items: center;
  @include site-header-link;
  background: $colour-transparent;
  border: 0;
  border-radius: 0;
  color: $colour-primary-grey;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  padding-top: 4px;
  height: $ingredients-gutter * 2;
  line-height: $ingredients-gutter * 2;
  z-index: 1;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: thin dotted;
    outline-offset: -1px;
    text-decoration: none;
  }

  &::after {
    bottom: -1px;
  }

  &.buttonHome {
    color: $colour-earl-grey;
    line-height: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;

    .buttonIcon {
      margin-bottom: $ingredients-unit;
    }

    .chevronIcon {
      margin-bottom: $ingredients-unit;
    }
  }

  .iconWrapper {
    position: relative;

    &.CANotPresent {
      &::after {
        position: absolute;
        right: 5px;
        top: 1px;
        background: $colour-error-red;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: solid 2px $colour-white;
        content: '';
      }
    }
  }
}

.buttonIcon {
  margin-right: $ingredients-unit * 2.5;
  margin-bottom: -$ingredients-unit * 0.5;
}

.chevronIcon {
  width: 2em;
  margin-bottom: -$ingredients-unit * 0.5;

  svg {
    height: 20px;
  }
}

.buttonText {
  position: relative;
  top: 1px;
}

.accountPopup {
  background: $colour-white;
  box-shadow: 0 3px 8px 0 $colour-earl-grey;
  position: absolute;
  right: 0;
  text-align: left;
  top: calc(100% + 1px);
  width: 252px;
  z-index: 2;

  li {
    border-top: 1px solid $colour-mushroom-grey;
    list-style: none;
    padding: $ingredients-unit * 2;
    position: relative;

    &:first-child,
    &:last-child {
      border-top: 0;
    }

    a {
      @extend %text-button;
      @include focus;
      align-items: center;
      display: flex;
      font-weight: 500;
      margin: 0;
      text: {
        align: left;
        transform: none;
      }
      width: 100%;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.accountMenuIcon {
  display: inline-block;
  line-height: initial;
  margin-right: $ingredients-gutter * 0.5;

  &.documentNormal {
    position: relative;
    left: -1px;
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

.iconNoCAWarning {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: $colour-error-red;
}

.signOut {
  padding: $ingredients-gutter * 0.5;
}

.signOutButton {
  background: $colour-white;
  border: 1px solid;
  cursor: pointer;
  font-weight: 500;
  padding: $ingredients-unit * 2;
  width: 100%;
}
