@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/focus';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/spacing';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/animation';
@import '../common';

$menu-link-list-background: $colour-white;

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.menuLinksList {
  @include menu-max-height;
  animation: {
    duration: $slide-duration;
    name: fade;
  }
  background-color: $menu-link-list-background;
  border: {
    right: 1px solid $colour-oyster-grey;
  }
  padding: 0;
  width: 100%;

  &.active {
    width: 24%;
  }
}

.self,
.ancestor,
.descendant {
  composes: menuLinksList;
}

.menuLinks {
  background-color: $menu-link-list-background;
  height: 100%;
  overflow: auto;
  margin: 0;
  padding: $menu-padding-top 0 $ingredients-unit * 5;
  -webkit-overflow-scrolling: touch; // smooth scroll on iOS

  li {
    align-items: center;
    font-size: 16px;
    font-weight: 200;
    list-style: none;
    padding: 0;
  }

  .all {
    font-size: inherit;
    font-weight: inherit;
  }
}

.offers {
  padding-top: $ingredients-unit;
  padding-bottom: $ingredients-unit;
}

.all {
  font-size: inherit;
}
