@import '~@johnlewispartnership/wtr-ingredients/dist/foundations/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/spacing';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/focus';

%menu-link {
  align-items: center;
  background: $colour-white;
  border: 0;
  border-bottom: 1px solid $colour-oyster-grey;
  color: $text-color;
  display: flex;
  font-weight: 300;
  justify-content: space-between;
  line-height: 1.5;
  outline-color: $colour-focus-blue;
  padding: ($ingredients-unit * 2);
  padding-left: ($ingredients-unit * 5);
  text-align: left;
  width: 100%;

  @media (hover: hover) {
    &:hover {
      background: $colour-waitrose-grey;
      color: $colour-white;
    }
  }

  @media (hover: none) {
    &:hover {
      text-decoration: none;
    }
  }

  &.active {
    background: $colour-waitrose-grey;
    color: $colour-white;
  }

  &:focus {
    @include focus-outline-inset;
    text-decoration: none;
  }
}

.nameWrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

a.link {
  @extend %menu-link;
}

button.button {
  @extend %menu-link;
}

.bold {
  font-weight: 500;
}

.link.offer {
  font-weight: 500;

  &,
  &:focus {
    color: $colour-offer-red;
  }

  &:hover {
    background-color: $colour-offer-red;
    color: $colour-white;
  }
}

.icon {
  margin: {
    right: 8px;
  }
}

.descendantsIcon {
  margin: {
    left: -0.25em;
    right: 5px;
  }
  float: right;
}

.showWarningIcon {
  position: relative;

  .warningIcon {
    position: absolute;
    color: $colour-error-red;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
}
