@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/focus';
@import '../common';

.forwardIcon {
  width: $menu-icon-width;
  display: inline-flex;
  position: absolute;
  right: $forward-icon-inset;
}

.loading {
  display: block;
  padding: 7px 20px;
}

.mLnk {
  align-items: center;
  color: $text-color;
  height: $menu-item-height;
  padding: {
    left: $menu-item-padding-left-desktop;
    right: $menu-item-padding-right;
  }
  position: relative;

  &[role='link'] {
    display: flex;
  }

  &:hover {
    @include menu-item-hover;
  }

  &:focus {
    @include focus-outline-inset;

    // TODO: Resolve style conflicts in Ingredients to not require !important flag (WPIP-54768)
    text-decoration: none !important;
    outline-offset: -$ingredients-unit !important;
  }

  &.active {
    background-color: $colour-waitrose-grey;
    color: $colour-white;

    &:hover,
    &:focus {
      background-color: $colour-waitrose-grey;
      color: $colour-white;
    }
  }

  &.more {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  &.favourites {
    &:hover {
      path {
        stroke: $colour-white;
      }
    }
  }

  &:global(.offerLink) {
    color: $colour-offer-red;
    font: {
      size: 16px;
      weight: 700;
    }
    text-transform: uppercase;

    &:hover {
      background-color: $colour-offer-red;
      color: $colour-white;
    }
  }

  &.offers {
    color: $colour-offer-red;
    display: flex;

    &:focus {
      color: $colour-offer-red;
    }

    &:hover {
      background-color: $colour-offer-red;
      color: $colour-white;
    }
  }
}

.more {
  padding-right: ($menu-item-padding-right + 12px);
}

.text {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
