@import '~@johnlewispartnership/wtr-ingredients/dist/foundations/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/spacing';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/focus';


.bold {
  font-weight: 500;
}

.menuCard {
  background-color: $colour-white;
  border: 1px solid $colour-mushroom-grey;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 12px 16px;
  font-size: 14px;

  &:hover,
  &:focus {
    @include focus;
    background-color: $colour-scallop-grey;
    text-decoration: none;

    .title {
      text-decoration: underline;
    }
  }

  .description {
    font-weight: 300;
    white-space: normal;
  }

  .icon {
    text-decoration: none;
  }

  .title {
    font-weight: 500;
    letter-spacing: 2.63px;
    margin-bottom: 4px;
  }
}

.linkImg {
  width: 80px;
  min-height: 80px;
  object-fit: cover;
  align-self: stretch;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 6px 8px 6px 12px;
}

.smallWrapper {
  margin: 0 40px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
