@import '~@johnlewispartnership/wtr-ingredients/dist/foundations/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing';
@import 'styles/settings/variables';

.container {
  width: 100%;
  background-color: $colour-white;

  @include media-breakpoint-up('lg') {
    background: none;
    padding-left: ($ingredients-unit * 10);
  }
}

a.link {
  display: block;
  font-weight: 300;
  line-height: 1.5;
  text-decoration: none;
  border-bottom: 1px solid $colour-oyster-grey;
  padding: ($ingredients-unit * 2);
  padding-left: ($ingredients-unit * 5);
  outline-offset: -4px !important; // TODO: Remove important when WPIP-55745 is fixed.

  @media (hover: hover) {
    &:hover {
      background: $colour-waitrose-grey;
      color: $colour-white;
    }
  }

  @media (hover: none) {
    &:hover {
      text-decoration: none;
    }
  }

  &.active {
    background: $colour-waitrose-grey;
    color: $colour-white;
  }

  &:focus {
    text-decoration: none;
  }

  @include media-breakpoint-up('lg') {
    display: inline-flex;
    background: none;
    border-bottom: 0;
    padding: 0;
    width: calc(100% - 16px);
    outline-offset: 4px !important; // TODO: Remove important when WPIP-55745 is fixed.
    margin-top: 6px;

    & + .link {
      margin-top: 12px;
    }

    @media (hover: hover) {
      &:hover {
        background: none;
        color: $colour-waitrose-grey;
      }
    }

    &:focus {
      text-decoration: underline;
    }
  }
}
