@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/focus';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/spacing';
@import '../../../styles/settings/variables';

%text-only-slot-button {
  background-color: $colour-white;
  border: 0;
  box-shadow: none;
  padding: 0;
}

.wrapper {
  position: relative;
  min-width: 85px;

  @include media-breakpoint-up('md') {
    min-width: 190px;
  }
}

.details {
  background: $colour-white;
  box-shadow: 0 3px 8px 0 $colour-earl-grey;
  position: absolute;
  top: calc(100% + #{$ingredients-unit * 2});
  right: 0;
  white-space: normal;

  @include media-breakpoint-up('md') {
    min-width: 240px;
  }

  @include media-breakpoint-down('md') {
    min-width: 260px;
  }

  .slotDateTime {
    display: flex;
    flex-direction: column;
    margin: 0 $ingredients-gutter ($ingredients-unit * 4);
  }

  .deliveryDetails {
    display: flex;
    flex-direction: column;
    margin: 0 $ingredients-gutter;
  }
}

.slotTime {
  text-transform: lowercase;
}

.header {
  border-bottom: 1px solid $colour-mushroom-grey;
  margin: $ingredients-gutter 0;
  padding: 0 $ingredients-gutter ($ingredients-gutter * 0.5);

  header {
    font: {
      family: Waitrose;
      size: 20px;
      weight: 500;
    }
    line-height: 1.5;
    white-space: nowrap;
  }
}

.address {
  display: block;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.button {
  margin: $ingredients-gutter;
}

.alert {
  min-width: $ingredients-gutter * 14;
  padding: $ingredients-gutter;
}

.textOnly {
  a {
    @extend %text-only-slot-button;

    &:hover,
    &:active {
      @extend %text-only-slot-button;
      text-decoration: underline;
    }
  }

  button {
    @extend %text-only-slot-button;

    &:hover,
    &:active {
      @extend %text-only-slot-button;
      text-decoration: underline;
    }
  }

  span {
    text-decoration: underline;
    text-decoration-skip-ink: none;
  }
}

.srOnly {
  @include sr-only;
}
