@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/spacing';

//== Grid system
$grid-vertical-gutter: $ingredients-unit * 4;

//== Links
$links-height: $ingredients-unit * 10;

//== Focus
$focus-outline-width: $ingredients-unit;
$font-path-waitrose: '/ecom/assets/fonts';
