@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';

$checkout-custom-breakpoint: (
  'xxs': 368px,
);

%logo {
  height: auto;
  width: 104px;
  margin: 0 !important;

  @include media-breakpoint-up('md') {
    width: 180px;
  }

  @include media-breakpoint-up('lg') {
    width: 200px;
  }
}

.logoNew {
  @extend %logo;
  display: none;
}

.logoRegular {
  @extend %logo;
  display: none;

  @include media-breakpoint-up('md') {
    display: inline;
  }
}

.logoSmall {
  @extend %logo;
  width: 87px;
  display: inline;

  @include media-breakpoint-up('lg') {
    display: none;
  }

  @include media-breakpoint-up('md') {
    display: inline;
    height: 25.6px;
    width: 104px;
  }
}

.srOnly {
  @include sr-only;
}
