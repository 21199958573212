@import '~@johnlewispartnership/wtr-ingredients/dist/styles/generic/normalize';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/generic/scaffolding';
@import './settings/variables';

@font-face {
  font-family: Waitrose;
  src: url('#{$font-path-waitrose}/GillSansNovaJL-Light.woff2') format('woff2'),
    url('#{$font-path-waitrose}/GillSansNovaJL-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Waitrose;
  src: url('#{$font-path-waitrose}/GillSansNovaJL-Medium.woff2') format('woff2'),
    url('#{$font-path-waitrose}/GillSansNovaJL-Medium.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Waitrose;
  src: url('#{$font-path-waitrose}/GillSansNovaJL-SemiBold.woff2') format('woff2'),
    url('#{$font-path-waitrose}/GillSansNovaJL-SemiBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: WaitroseGlyph;
  src: url('#{$font-path-waitrose}/Waitrose-glyph-v6.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Sebenta;
  src: url('#{$font-path-waitrose}/Sebenta-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: SohneSchmal;
  src: url('#{$font-path-waitrose}/SohneSchmal-Halbfett.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.common-root {
  font-family: Waitrose, 'Gill Sans', 'Gill Sans MT', Tahoma, sans-serif;
}
