@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '../../../styles/settings/variables';

.shoppingSummary {
  @include media-breakpoint-up('md') {
    margin-left: 8px;
  }

  @include media-breakpoint-down('sm') {
    align-items: center;
    display: flex;

    .trolleyBtn {
      border: 0;
      margin-top: 0;
      padding: 0;
      z-index: 1;
      position: relative;
      display: block;
    }
  }
}

.trolleyActions {
  position: relative;

  a {
    min-width: unset;
  }
}

%trolley-button {
  background-color: $colour-white;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  @include media-breakpoint-down('sm') {
    font: {
      family: Waitrose;
      size: 14px;
    }
    padding: 2px 35px 0 6px;
    text-align: right;
    vertical-align: initial;

    &,
    &:hover,
    &:focus,
    &:active {
      background-color: $colour-white;
      border: 0;
      box-shadow: none;
      color: $text-color;
    }
  }

  @include media-breakpoint-up('md') {
    border: 1px solid $colour-primary-grey;
    height: 40px;
    padding: 0;
    width: 190px;

    &:hover,
    &:focus {
      border-width: 2px;
    }
  }
}

.trolleyLink {
  @extend %trolley-button;
  display: flex;
  overflow: hidden;
  padding: 0;
  box-shadow: none;

  @include media-breakpoint-up('md') {
    width: 144px;
  }

  &:focus {
    @extend %trolley-button;

    outline: {
      color: $colour-focus-blue;
      offset: $focus-outline-width;
      style: solid;
      width: $focus-outline-width;
    }

    border: 1px solid $colour-primary-grey;
    right: 0;
    padding: 1px 19px;
    z-index: 2;
  }

  &:hover {
    box-shadow: none;

    span {
      text-decoration: underline;
    }
  }
}
